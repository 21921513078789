<template>
    <a
        href="#"
        v-if="!isInstalled && prompt"
        @click.prevent="showInstallPrompt"
    >
        <i>
            <font-awesome-icon :icon="['fal', 'download']" />
        </i>
        {{ $t('global.install_pwa') }}
    </a>
</template>

<script>
export default {
    data: () => ({
        isInstalled: false,
        prompt: window.groomyPwa.prompt
    }),

    created() {
        window.addEventListener('appinstalled', this.onAppInstalled)
    },

    destroyed() {
        window.removeEventListener('appinstalled', this.onAppInstalled)
    },

    methods: {
        onAppInstalled() {
            this.isInstalled = true
        },
        showInstallPrompt () {
            this.prompt.prompt();
        }
    }
}
</script>
